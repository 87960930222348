import Field from "./field.jsx";
import NumberFilter from "../filters/number.js";
import MoneyFilter from "../filters/money.js";
import RequisitesFilter from "../filters/requisites.js";
import RequiredValidator from "../validators/required.js";
import EmailValidator from "../validators/email.js";
import HardpasswordValidator from "../validators/hardpassword.js";
import RequisitesValidator from "../validators/requisites.js";
import LicenseAgreement from "./license-agreement.jsx";
import PasswordHintPopup from "../../password-hint-popup.jsx";

export default class ValidatableField extends Field {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            valid: null,
            showPopupHint: false,
        };
    }

    render() {
        const fieldClassName = this.getFieldClassName(),
            label = this.renderLabel(),
            control = this.renderControl(),
            error = this.renderError();

        switch (this.props.type) {
            case "hidden":
                return <Input {...this.props} value={this.getFieldValue()} />;
            case "checkbox":
            case "radio":
                return (
                    <div className={fieldClassName}>
                        {error}
                        {control}
                        {label}
                    </div>
                );
            case "agreement":
                let labelButton;
                if (this.props.data && this.props.data.labelButton) {
                    labelButton = (
                        <button
                            type="button"
                            onClick={this.handleAgreementLabelButtonClick.bind(
                                this,
                                this.props
                            )}
                        >
                            {this.props.data.labelButton}
                        </button>
                    );
                }
                return (
                    <div className={fieldClassName}>
                        {control}
                        {label}
                        {labelButton}
                    </div>
                );
            case "license-agreement":
                return (
                    <LicenseAgreement
                        {...this.props}
                        fieldId={this.fieldId}
                        fieldClassName={fieldClassName}
                        onFieldChange={this.handleChange}
                        onFieldClick={this.handleClick}
                    />
                );
            case "button":
            case "submit":
                <div className={fieldClassName}>
                    {error}
                    <div className="form-control">{control}</div>
                </div>;
            case "password":
                if (this.props.validators && this.props.validators.includes('hardpassword')) {
                    return (
                        <div className={fieldClassName}>
                            {label}
                            <button className="popup-info"
                                onClick={this.handlePasswordHintPopup.bind(this)}>
                            </button>
                            <PasswordHintPopup
                                show={this.state.showPopupHint}
                                onCloseButtonClick={this.handlePasswordHintCloseButtonClick.bind(
                                    this
                                )}
                            />
                            <div className="form-control">{control}</div>
                        </div>
                    );
                }
            default:
                return (
                    <div className={fieldClassName}>
                        {error}
                        {label}
                        <div className="form-control">{control}</div>
                    </div>
                );
        }
    }

    renderError() {
        if (!this.state.error) {
            return null;
        }

        return (
            <div className="form-field-error">
                <p>{this.state.error}</p>
            </div>
        );
    }

    handleChange(e, component) {
        if (e.target.value && this.props.filters) {
            const filteredValue = this.filterValue(e.target.value);
            if (component && component.setValue) {
                component.setValue(filteredValue);
            } else {
                e.target.value = filteredValue;
            }
        }

        super.handleChange(e);
    }

    handleAgreementLabelButtonClick(field, e) {
        this.handleClick(e, null, field);
    }

    filterValue(value) {
        if (!this.props.filters || !this.props.filters.length) {
            return value;
        }

        for (let i in this.props.filters) {
            switch (this.props.filters[i]) {
                case "number":
                    value = NumberFilter(value);
                    break;
                case "money":
                    value = MoneyFilter(value);
                    break;
                case "inn":
                case "kpp":
                case "ogrn":
                case "bank_account_number":
                case "bik":
                    value = RequisitesFilter(value, this.props.filters[i]);
                    break;
            }
        }

        return value;
    }

    validate() {
        let valid = true,
            error = null;

        if (!this.props.validators || !this.props.validators.length) {
            return valid;
        }

        for (let i in this.props.validators) {
            switch (this.props.validators[i]) {
                case "required":
                    if (!RequiredValidator(this.getFieldValue())) {
                        valid = false;
                    }
                    break;
                case "email":
                    if (!EmailValidator(this.getFieldValue())) {
                        valid = false;
                    }
                    break;
                case "inn":
                case "kpp":
                case "ogrn":
                case "bank_account_number":
                case "bik":
                    if (
                        !RequisitesValidator(
                            this.getFieldValue(),
                            this.props.validators[i]
                        )
                    ) {
                        valid = false;
                    }
                    break;
                case "hardpassword":
                    if (!HardpasswordValidator(this.getFieldValue())) {
                        valid = false;
                    }
                    break;
                }
        }

        this.setState({ valid: valid, error: error });

        return valid;
    }

    getFieldClassName() {
        let className = super.getFieldClassName();
        if (this.state.valid === false) {
            className += " field-error";
        }
        return className;
    }

    handlePasswordHintPopup(e) {
        e.preventDefault();
        this.showPasswordHint();
    }

    handlePasswordHintCloseButtonClick() {
        this.hidePasswordHint();
    }

    showPasswordHint() {
        this.setState({
            showPopupHint: true,
        });
    }

    hidePasswordHint() {
        this.setState({
            showPopupHint: false,
        });
    }
}
