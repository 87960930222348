import newId from "../utils/new-id.js";
import Input from "./input.jsx";
import Textarea from "./textarea.jsx";
import Select from "./select-parser.jsx";
import ColorSelect from "./colorselect.jsx";
import Checkput from "./custom-checkput.jsx";
import Files from "./files.jsx";
import Datepicker from "./datepicker.jsx";
import Price from "./price.jsx";
import Colorpicker from "./colorpicker.jsx";
import ColorpickerPalette from "./colorpicker-palette.jsx";
import AutocompleteInput from "./autocomplete.jsx";
import TextList from "./text-list.jsx";
import Agreement from "./agreement.jsx";
import { getNestedValue } from "../../../../components/helpers/objects-helper.js";
import Phone from "./phone.jsx";
import PasswordInput from "./password-input.jsx";
import HardPasswordInput from "./hard-password-input.jsx";

export default class Field extends React.Component {
    constructor(props) {
        super(props);

        this.fieldRef = React.createRef();
        this.fieldId = newId("form-field-");
        this.fieldValue = null;
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.getFieldValue = this.getFieldValue.bind(this);
    }

    render() {
        const fieldClassName = this.getFieldClassName(),
            label = this.renderLabel(),
            control = this.renderControl();

        switch (this.props.type) {
            case "hidden":
                return <Input {...this.props} value={this.getFieldValue()} />;
            case "checkbox":
            case "radio":
                return (
                    <div className={fieldClassName}>
                        {control}
                        {label}
                    </div>
                );
            case "agreement":
                return (
                    <div className={fieldClassName}>
                        {control}
                        {label}
                        <button type="button">blabla</button>
                    </div>
                );
            case "button":
            case "submit":
                return (
                    <div className={fieldClassName}>
                        <div className="form-control">{control}</div>
                    </div>
                );
            case "plaintext":
                return (
                    <div className={fieldClassName}>
                        <p>{this.props.value}</p>
                    </div>
                );
            default:
                return (
                    <div className={fieldClassName}>
                        {label}
                        <div className="form-control">{control}</div>
                    </div>
                );
        }
    }

    renderLabel() {
        switch (this.props.type) {
            case "hidden":
            case "button":
            case "submit":
            case "phone":
                return null;
            default:
                if (this.props.label) {
                    return (
                        <label htmlFor={this.fieldId} className="form-label">
                            {this.props.label}
                        </label>
                    );
                }
                return null;
        }
    }

    renderControl() {
        switch (this.props.type) {
            case "hidden":
                return <Input {...this.props} value={this.getFieldValue()} />;
            case "checkbox":
            case "radio":
                return (
                    <Checkput
                        {...this.props}
                        id={this.fieldId}
                        onChange={this.handleChange}
                        ref={this.fieldRef}
                    />
                );
            case "agreement":
            case "license-agreement":
                return (
                    <Agreement
                        {...this.props}
                        id={this.fieldId}
                        onChange={this.handleChange}
                        ref={this.fieldRef}
                    />
                );
            case "button":
            case "submit":
                return (
                    <button
                        className="form-button"
                        type={this.props.type}
                        value={this.getFieldValue()}
                        onClick={this.handleClick}
                    >
                        {this.props.label}
                    </button>
                );
            case "textarea":
                return (
                    <Textarea
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                    />
                );
            case "select":
                return (
                    <Select
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onClick={this.handleClick}
                        ref={this.fieldRef}
                    />
                );
            case "colorselect":
                return (
                    <ColorSelect
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        ref={this.fieldRef}
                    />
                );
            case "files":
                return (
                    <Files
                        {...this.props}
                        id={this.fieldId}
                        options={getNestedValue(this.props.data, "options")}
                        onChange={this.handleChange}
                        ref={this.fieldRef}
                    />
                );
            case "datepicker":
                return (
                    <Datepicker
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        onChange={this.handleChange}
                        ref={this.fieldRef}
                    />
                );
            case "price":
                return (
                    <Price
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        ref={this.fieldRef}
                    />
                );
            case "colorpicker":
                return (
                    <Colorpicker
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        onChange={this.handleChange}
                        ref={this.fieldRef}
                    />
                );
            case "colorpicker-palette":
                return (
                    <ColorpickerPalette
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        onChange={this.handleChange}
                        ref={this.fieldRef}
                    />
                );
            case "autocomplete":
                return (
                    <AutocompleteInput
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        options={getNestedValue(this.props.data, "options")}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        ref={this.fieldRef}
                    />
                );
            case "text-list":
                return (
                    <TextList
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        options={getNestedValue(this.props.data, "options")}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        ref={this.fieldRef}
                    />
                );
            case "phone":
                return (
                    <Phone
                        {...this.props}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        ref={this.fieldRef}
                    />
                );
            case "password":
                if (this.props.validators && this.props.validators.includes('hardpassword')) {
                    return (
                        <HardPasswordInput
                            {...this.props}
                            id={this.fieldId}
                            value={this.getFieldValue()}
                            onChange={this.handleChange}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            ref={this.fieldRef}
                        />
                    );
                } else {
                    return (
                        <PasswordInput
                            {...this.props}
                            id={this.fieldId}
                            value={this.getFieldValue()}
                            onChange={this.handleChange}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            ref={this.fieldRef}
                        />
                    );
                }
            default:
                return (
                    <Input
                        {...this.props}
                        id={this.fieldId}
                        value={this.getFieldValue()}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        ref={this.fieldRef}
                    />
                );
        }
    }

    handleChange(e) {
        var target = e.target;

        switch (this.props.type) {
            case "agreement":
            case "license-agreement":
                this.fieldValue = e.target.checked;
                break;
            default:
                this.fieldValue = e.target.value;
                break;
        }

        var handler = this.props.onChange || function () {},
            props = this.getProps(target);

        handler(e, props, this);
    }

    handleClick(e, component, params) {
        const handler = this.props.onClick || function () {};
        handler(e, this, params);
    }

    handleFocus(e) {
        var target = e.target,
            handler = this.props.onFocus || function () {},
            props = this.getProps(target);

        handler(e, props, this);
    }

    handleBlur(e) {
        var target = e.target,
            handler = this.props.onBlur || function () {},
            props = this.getProps(target);

        handler(e, props, this);
    }

    getProps(target) {
        var props = {}; // мы передадим измененные свойства компонента, кроме функций

        for (var i in this.props) {
            if (typeof this.props[i] == "function") {
                continue;
            }

            props[i] = this.props[i];
        }

        switch (this.props.type) {
            case "checkbox":
            case "radio":
            case "agreement":
                props.checked = target.checked;
                break;
            default:
                props.value = target.value;
                break;
        }

        return props;
    }

    getFieldClassName() {
        return (
            "form-field field-type-" +
            this.props.type +
            " field-name-" +
            this.props.name
        );
    }

    getFieldName() {
        return this.props.name;
    }

    getFieldValue() {
        if (this.fieldValue === null) {
            return this.props.value;
        }

        return this.fieldValue;
    }

    prepareFormData(formData) {
        if (
            !this.fieldRef ||
            !this.fieldRef.current ||
            typeof this.fieldRef.current.prepareFormData !== "function"
        ) {
            return formData;
        }

        return this.fieldRef.current.prepareFormData(formData);
    }
}
