export default class PasswordHintPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="popup-hint show">
                <div className="popup-hint-container">
                    <span className="popup-hint-icon"></span>
                    <div className="popup-hint-annotation">Для обеспечения безопасности аккаунта, пароль должен соответствовать следующим требованиям: </div>
                    <div className="popup-hint-body">
                        Длина: не менее 8 символов. 
                        Буквы: должны присутствовать как строчные, так и прописные буквы. 
                        Цифры: должны присутствовать хотя бы одна цифра. Спецсимволы: должны присутствовать хотя бы один спецсимвол (например, !@#$%^&*)
                    </div>
                    <button className="close-button"
                        onClick={this.handleCloseButtonClick.bind(this)}>
                    </button>
                </div>
            </div>
        );
    }

    handleCloseButtonClick(e) {
        const handler = this.props.onCloseButtonClick || function(){};
        handler(e);
    }
}
