import newId from "../utils/new-id.js";
import PasswordInput from "./password-input.jsx";

export default class HardPasswordInput extends PasswordInput {
    constructor(props) {
        super(props);

        this.green = React.createRef();
        this.yellow = React.createRef();
        this.red = React.createRef();
        this.input = React.createRef();

        this.state.password = this.props.value;
    }

    render() {
        // let passwordCheck = this.renderPasswordCheck();

        // все остальные компоненты (textarea, select, chekput) не нуждаются в новом свойстве key
        // они перерисовывются когда приходит новый value
        // но этот инпут почему-то не перерисовывается...
        return (
            <div className='password-input' type='password'>
                <input
                    className='form-input'
                    type={this.state.type}
                    name={this.props.name}
                    defaultValue={this.props.value || this.state.defaultValue}
                    key={newId("input-key-")}
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    autoComplete={this.props.autocomplete ? "on" : "off"}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    ref={this.input}
                />
                <button
                    className='eye-icon fa fa-eye'
                    onClick={this.handleHidePassword.bind(this)}
                ></button>
                <div className='form-field-password-check active-green' style={{display:'none'}} 
                    ref={this.green}>
                    <span className='password-check-hint-green'>
                        Сложный пароль
                    </span>
                </div>
                <div className='form-field-password-check active-yellow' style={{display:'none'}} 
                    ref={this.yellow}>
                    <span className='password-check-hint-yellow'>
                        Средний пароль
                    </span>
                </div>
                <div className='form-field-password-check active-red' style={{display:'none'}}
                    ref={this.red}>
                    <span className='password-check-hint-red'>
                        Слабый пароль
                    </span>
                </div>
                <div className="form-field-password-generator">
                    <button
                    onClick={this.handlePasswordGenerateClick.bind(this)}>
                        Сгенерировать пароль
                    </button>
                </div>
            </div>
        );
    }

    handleChange(e) {
        this.checkPassword(e.target.value);

        const handler = this.props.onChange || function () {};
        handler(e);
    }

    checkPasswordHardness(password) {
        let criteria = null;
        let examRegs = {
            lowerCase:    { re: /[a-z]+/,  exam: true },
            upperCase:    { re: /[A-Z]+/,  exam: true },
            digits:       { re: /[0-9]+/, exam: true },
            specialChars: { re: /[\`\"\'\=\+\\\/\[\].:,;?!@#$%^&*()<>{}_-~|]/, exam: true },
            length:       { re: /^.{8,}/, exam: true },
        };
        
        for (let key in examRegs) {
            let re = examRegs[key]["re"];
            let ex = examRegs[key]["exam"];
            if (re.test(password) === ex) {
                criteria = criteria + 1;
            }
        }

        if (criteria > 4) {
            return "green";
        } else if (criteria > 3) {
            return "yellow";
        } else {
            return "red";
        }
    }

    checkPassword(value) {
        this.green.current.style.display = 'none';
        this.yellow.current.style.display = 'none';
        this.red.current.style.display = 'none';

        if (!value) {
            return null;
        }

        switch (this.checkPasswordHardness(value)) {
            case "green":
                this.green.current.style.display = 'block';
                break;
            case "yellow":
                this.yellow.current.style.display = 'block';
                break;
            case "red":
                this.red.current.style.display = 'block';
                break;
        }
    }

    handlePasswordGenerateClick(e) {
        e.preventDefault();

        let specChars = '.:,;?!@#$%^&*_-+=()<>{}[]\'"`/_~|\\';
        let numbers = '0123456789';
        let password = specChars[Math.floor(Math.random() * specChars.length)];
        password += Math.random().toString(36).substring(5);
        password += numbers[Math.floor(Math.random() * numbers.length)];
        password += String.fromCharCode(65+Math.floor(Math.random() * 26));
        
        this.input.current.value = password;
        this.checkPassword(password);
    }
}
